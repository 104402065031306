import React, { useState } from 'react';
import styled from 'styled-components';
import Arrow from '@catalogo/ui-americanas-desktop-svg/arrow.svg';

const ZionText = ({ publication }) => {
  const { textContent, numberOfLines } = publication;
  const [showMoreText, setShowMoreText] = useState(true);

  return (
    <>
      <TextContent
        className="text"
        numberOfLines={showMoreText && numberOfLines ? numberOfLines : 'initial'}
        showMoreText={showMoreText}
      >
        {textContent}
      </TextContent>
      {!!numberOfLines && (
        <ToggleShowMore onClick={() => setShowMoreText(!showMoreText)}>
          {showMoreText ? 'Mostrar mais' : 'Mostrar menos'} <ArrowSvg showMoreText={showMoreText} rotate={0} />
        </ToggleShowMore>
      )}
    </>
  );
};

export default ZionText;

const TextContent = styled.p`
  color: ${({ theme }) => theme.titleColor};
  font-size: 14px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
  -webkit-box-orient: vertical;
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
`;

const ToggleShowMore = styled.a`
  color: ${({ theme }) => theme.grey.dark};
  cursor: pointer;
  font-size: 14px;
  text-transform: ${({ theme }) => theme.titleTransform};
  text-decoration: underline;
  display: flex;
  justify-content: center;
  margin: 12px 0 0;
  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const ArrowSvg = styled(Arrow)`
  fill: ${({ theme }) => theme.grey.black};
  width: 10px;
  height: auto;
  margin-left: 0.5rem;
  transform: rotate(${props => (props.showMoreText ? '0deg' : '180deg')});
`;
